@use "sass:math";

@use '@angular/material' as mat;
@import '../@fury/styles/_var.scss';

.jms-success-report {
  display: flex;
  padding: 1.6em 2em;
  color: #117825;
  background-color: fade-out( #117825, .95);
  border-bottom: 1px solid rgba(0,0,0,.15);
}

.jms-error-report {
  display: flex;
  flex-direction: column;
  padding: 1.6em 2em;
  color: #B71C1C;
  background-color: fade-out( #B71C1C, .95);
  border-bottom: 1px solid rgba(0,0,0,.15);

  a {
    color: $fcmat-primary;
    text-decoration: none;
    font-size: 1em;
    margin-top: 3px;

    &:hover {
      opacity: .7;
    }
  }
}

.background-primary {
  background-color: $fcmat-background-dark !important;
}

mat-checkbox {
  svg {
    color: white !important;
  }
}

/*White form fields*/
$input-color: rgba(255,255,255,.9);
$label-color: rgba(255,255,255,.7);
$background-color: rgba(255,255,255,.05);

mat-form-field.mat-mdc-form-field.fcmat-white {
  margin-top: -10px;

  input {
    caret-color: $input-color !important;
  }

  mat-label {
    color: $label-color !important;
  }

  .mdc-text-field--filled {
    background-color: $background-color;
  }

  input, mat-select {
    color: $input-color !important;
  }

  .mat-mdc-select-arrow {
    color: $input-color !important;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
    border-bottom-color: $label-color !important;
  }

  .mdc-text-field--filled.mdc-text-field--focused .mdc-line-ripple::before {
    border-bottom-color: $input-color !important;
  }

  .mdc-line-ripple--active, .mdc-line-ripple::after {
    border-bottom-color: $input-color !important;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: $background-color !important;
  }


  .mat-datepicker-toggle {
    color: $label-color !important;
  }
  /*:hover {
    .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
      border-bottom-color: $input-color !important;
    }
  }*/
}

.mat-mdc-checkbox.fcmat-white {
  margin-top: -10px;

  label {
    color: $input-color;
  }

  .mdc-checkbox__background, .mdc-checkbox__ripple {
    border-color: $input-color !important;
  }

  &.mat-mdc-checkbox-checked .mdc-checkbox__background, .mdc-checkbox__ripple {
    background-color: $background-color !important;
  }
}


.fcmat-white {
  .mdc-form-field {
    color: white;
  }

  .mdc-radio__outer-circle {
    border-color: $label-color !important
  }

  .mdc-radio__inner-circle {
    border-color: $input-color !important
  }

}

button.fcmat-white {
  background-color: rgba(255,255,255,.8) !important;
  color: $fcmat-primary !important;
}

.mat-mdc-mini-fab {
  /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-button-wrapper {
    padding: unset !important;
    display: unset !important;
    line-height: 28px !important;
  }

  mat-icon {
    color: white;
    line-height: unset;
    height: unset !important;
    width: unset !important;
  }
}


/*REPORT VIEWER*/
.report-viewer-dialog {
  width: 100%;
  min-height: calc(100vh - 90px);
  height: auto;

  mdc-dialog__container {
    display: flex;
    flex-direction: column;
  }

  .mat-mdc-dialog-content {
    max-height:unset;
  }
}

.multi-dialog {
  .mat-mdc-dialog-container, .mdc-dialog__surface {
    background-color: $fcmat-background-verylight !important;
  }
}

.wide-dialog {
  width: 85vw;
}

.tall-dialog {
  .mat-mdc-dialog-content {
    max-height: 80vh;
    height: 80vh;
  }
}

.tall-wide-dialog {
  width: 90vw;

  .mat-mdc-dialog-content {
    max-height: 80vh;
    height: 80vh;
  }
}

/*FCMAT*/
.fcmat-section {
  margin: .5em 0 .5em 0;
}

.fcmat-label {
  opacity:.7;
}

.fcmat-label-value {
  display: flex;

  .fcmat-label {
    width: 50%;
    text-align: right;
    margin-right: 1em;
  }
}

.fcmat-dialog-heading {
  color: $fcmat-dark;
  font-weight: 600;
  margin-bottom: .5em;
  padding: 20px 24px 0 24px;
}

.mdc-dialog__title::before {
  height:unset !important;
}

.mat-mdc-dialog-surface {
  padding-top: 10px;
}

mat-dialog-content {
  overflow-x: hidden !important;
}

  mat-footer-row {
    font-weight: bold;

    .mat-mdc-footer-cell {
      flex-direction: column;
    }
  }
  /*Grid heights*/
  table.fcmat-compact, fury-list.fcmat-compact {
    /*mat-row, mat-footer-row {
    height: 30px !important;
    min-height: 30px;
  }*/
    mat-header-row {
      height: 36px !important;
      min-height: 36px;
      -webkit-height: 36px !important;
    }

    .fury-list-table .fury-list-header {
      height: 56px !important;
      -webkit-height: 56px !important;
      flex-grow: 0;
    }

    .mat-mdc-paginator-container {
      min-height: 36px !important;
      max-height: 36px;
    }

    button {
      line-height: 28px;
      margin: 2px 0 2px 0;
    }

    .mat-mdc-icon-button {
      height: 30px;
      -webkit-height: 30px !important;
    }
  }
.mdc-data-table__header-cell, .mdc-data-table__cell {
  box-sizing: content-box !important;
}

mat-header-cell {
  line-height: 1em !important;
}

/*Select list - This messes up list filtering*/
/*.mat-select-panel .mat-optgroup-label, .mat-select-panel .mat-option {
  line-height: 2.5em !important;
  height: 2.5em !important;
}
.mat-select-search-inside-mat-option .mat-select-search-input {
  height: 2.5em !important;
  line-height: 2.5em !important;
}

.mat-select-search-clear {
  width: 25px !important;
  height: 25px !important;
  line-height: 25px !important;
}*/
/*BASIC LAYOUT*/
.fury-default .padding {
  padding: 8px;
}

  .fury-page-layout-simple .fury-page-layout-content {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .fury-default .mat-mdc-select-panel {
    background-color: #EAEAEA;
  }

  .mat-mdc-tab-body-content {
    overflow: hidden;

    .fcmat-tab-content {
      padding: 8px;
      height: 100%;
      overflow: hidden;

      .fcmat-section-all {
        height: 100%;

        fury-list {
          max-height: 100%;
          overflow: hidden;
        }
      }
    }
  }

.column-filter-menu {
  .mat-mdc-menu-content {
    display: flex;
    flex-direction: column;

    .mat-menu-item {
      display: flex;
      border: none;
      background: transparent;
    }
  }
}

.system-table {
  padding: 1em;
  margin: 0;
  background-color: $fcmat-background-light;

  mat-paginator {
    border-radius: 0 0 8px 8px;
  }
}

  mat-sidenav-content {
    width: 100%;

    .content {
      flex: 1 1 auto;
      overflow: hidden !important;

      .content-inner {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
      }
    }
  }

  fury-page-layout-header {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
  }
  /*  job requests*/

  mat-vertical-stepper.fcmat-stepper {
    /* display: flex;
  flex-direction: column;*/
    /*height: 100%;*/
    /*overflow: hidden;*/

    .mat-step {
      /*display: flex;
    flex-direction: column;*/
      /*height: 100%;*/
      /*overflow: hidden;*/

      .mat-vertical-content-container {
        /* display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;*/

        .mat-vertical-content {
          /*  display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;*/
        }
      }
    }
  }

  mat-horizontal-stepper.fcmat-stepper {
    display: flex;
    flex-direction: column;
    /*height: 100%;*/
    overflow: hidden;

    .mat-step {
      display: flex;
      flex-direction: column;
      /*height: 100%;*/
      overflow: hidden;
    }

    .mat-horizontal-content-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
    }

    .mat-horizontal-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
    }
  }

  .mat-horizontal-stepper-content {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    max-height: 100%;
  }

  .wizard-form {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    .content {
      overflow: auto;
    }
  }

  .wizardcard {
    /*display: flex;
  flex-direction: column;*/
    margin: $spacing;
    max-width: 100%;
    border-radius: $border-radius;
    /*overflow: hidden;*/
    background: white;

    @include mat.elevation(10);

    .heading {
      font: var(--font-title);
    }

    .subheading {
      font: var(--font-subheading-1);
    }

    .content {
      margin: $spacing*0.5 0;

      .checkbox {
        margin-top: $spacing*0.5;
      }
    }

    .actions {
      margin-top: $spacing;
      padding: $spacing math.div($spacing, 3) 0;
      border-top: 1px solid rgba(0,0,0,.12);
      flex-shrink: 0;
    }
  }

  .mat-sort-header-content {
    line-height: normal;
  }

  .mat-mdc-radio-button .mdc-radio {
    padding: 0 !important;
  }

  mat-card {
    padding: 16px;
  }


  .mat-mdc-paginator-range-actions {
    height: 20px;
  }

  mat-tab-header {
    border-bottom: #eaeaea 0.5px solid !important;
  }


  @media(max-width:600px) {
    fury-footer {
      display: none !important;
    }
  }
