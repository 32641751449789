@use '@angular/material' as mat;
//@import '@angular/material/theming';
$mat-theme-ignore-duplication-warnings: true;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker hue.
// $theme-primary: mat-palette($mat-teal);
// $theme-accent:  mat-palette($mat-light-blue, 500, 200, 700);

// define a real custom palette (using http://mcg.mbitson.com)
$fcmat-background: ( 50 : #e3e3ed, 100 : #b9b9d2, 200 : #8b8ab4, 300 : #5c5b96, 400 : #393880, 500 : #161569, 600 : #131261, 700 : #100f56, 800 : #0c0c4c, 900 : #06063b, A100 : #7272ff, A200 : #3f3fff, A400 : #0c0cff, A700 : #0000f2, contrast: ( 50 : #000000, 100 : #000000, 200 : #000000, 300 : #ffffff, 400 : #ffffff, 500 : #ffffff, 600 : #ffffff, 700 : #ffffff, 800 : #ffffff, 900 : #ffffff, A100 : #000000, A200 : #ffffff, A400 : #ffffff, A700 : #ffffff, ) );
$fcmat-accent: ( 50 : #e8f8f7, 100 : #c5eceb, 200 : #9ee0de, 300 : #77d4d1, 400 : #5acac7, 500 : #3dc1bd, 600 : #37bbb7, 700 : #2fb3ae, 800 : #27aba6, 900 : #1a9e98, A100 : #d4fffd, A200 : #a1fffb, A400 : #6efff8, A700 : #55fff7, contrast: ( 50 : #000000, 100 : #000000, 200 : #000000, 300 : #ffffff, 400 : #ffffff, 500 : #ffffff, 600 : #ffffff, 700 : #ffffff, 800 : #ffffff, 900 : #ffffff, A100 : #ffffff, A200 : #ffffff, A400 : #ffffff, A700 : #ffffff, ) );
$fcmat-primary: ( 50 : #e6eafd, 100 : #c2cafa, 200 : #99a7f6, 300 : #7083f2, 400 : #5169f0, 500 : #324eed, 600 : #2d47eb, 700 : #263de8, 800 : #1f35e5, 900 : #1325e0, A100 : #ffffff, A200 : #dcdeff, A400 : #a9afff, A700 : #9097ff, contrast: ( 50 : #000000, 100 : #000000, 200 : #000000, 300 : #ffffff, 400 : #ffffff, 500 : #ffffff, 600 : #ffffff, 700 : #ffffff, 800 : #ffffff, 900 : #ffffff, A100 : #000000, A200 : #000000, A400 : #000000, A700 : #000000, ) );


$theme-primary-hue: 500;
$theme-background-hue: 500; 
$theme-accent-hue: 600;
$theme-warn-hue: 500;

$theme-primary: mat.define-palette($fcmat-primary, $theme-primary-hue);
$theme-background: mat.define-palette($fcmat-background, $theme-background-hue);
$theme-accent: mat.define-palette($fcmat-accent, $theme-accent-hue);
$theme-warn: mat.define-palette(mat.$red-palette, $theme-warn-hue);

.fury-default {
  $theme: mat.define-light-theme(( color: ( primary: $theme-primary, accent: $theme-accent, warn: $theme-warn, ), typography: $config, density: 0, ));
  @include mat.all-component-themes($theme);

  @import "../@fury/styles/themes/default";
  background: $theme-background;
  color: $theme-text;

  @import "./pages/coming-soon/coming-soon.component.theme";
  @import "./layout/config-panel/config-panel.component.theme";
}

/*.fury-light {
  $theme: mat.define-light-theme(( color: ( primary: $theme-primary, accent: $theme-accent, warn: $theme-warn, ), typography: $config, density: 0, ));
  @include mat.all-component-themes($theme);

  @import "../@fury/styles/themes/light";
  background: $theme-background;
  color: $theme-text;

  @import "./pages/coming-soon/coming-soon.component.theme";
  @import "./layout/config-panel/config-panel.component.theme";
}

.fury-dark {
  $theme: mat.define-light-theme(( color: ( primary: $theme-primary, accent: $theme-accent, warn: $theme-warn, ), typography: $config, density: 0, ));
  @include mat.all-component-themes($theme);

  @import "../@fury/styles/themes/dark";
  background: $theme-background;
  color: $theme-text;

  @import "./pages/coming-soon/coming-soon.component.theme";
  @import "./layout/config-panel/config-panel.component.theme";
}

.fury-flat {
  $theme: mat.define-light-theme(( color: ( primary: $theme-primary, accent: $theme-accent, warn: $theme-warn, ), typography: $config, density: 0, ));
  @include mat.all-component-themes($theme);

  @import "../@fury/styles/themes/flat";
  background: $theme-background;
  color: $theme-text;

  @import "./pages/coming-soon/coming-soon.component.theme";
  @import "./layout/config-panel/config-panel.component.theme";
}*/

// *** Contrast fix example of custom accent color ***
.mat-mdc-raised-button.mat-accent,
.mat-mdc-unelevated-button.mat-accent,
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  color: mat.get-color-from-palette( $fcmat-accent, default-contrast ) !important;
  --mat-mdc-button-persistent-ripple-color: mat.get-color-from-palette( $fcmat-accent, default-contrast ) !important;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1); //Insert custom rgba contrast color
  --mdc-filled-button-label-text-color: #ffffff !important;
}

.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mdc-fab-icon-color: mat.get-color-from-palette( $fcmat-accent, default-contrast );
  --mat-mdc-fab-color: mat.get-color-from-palette( $fcmat-accent, default-contrast );
}

.mat-mdc-raised-button.mat-primary,
.mat-mdc-unelevated-button.mat-primary,
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  color: mat.get-color-from-palette( $fcmat-primary, default-contrast ) !important;
  --mat-mdc-button-persistent-ripple-color: mat.get-color-from-palette( $fcmat-primary, default-contrast ) !important;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1); //Insert custom rgba contrast color
  --mdc-filled-button-label-text-color: #ffffff !important;
}

.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mdc-fab-icon-color: mat.get-color-from-palette( $fcmat-primary, default-contrast );
  --mat-mdc-fab-color: mat.get-color-from-palette( $fcmat-primary, default-contrast );
}
/*
* {
  --mdc-checkbox-selected-checkmark-color: #fff !important; //Using mat.get-color-from-palette() doesn't work for me so I hardcoded the color
}*/
