@use '@angular/material' as mat;
//@import '@angular/material/theming';
@import '~normalize.css/normalize.css';
/*@import "~simplebar/dist/simplebar.css";*/

@import "./var";
@import "./theme";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($config);`
@include mat.all-component-typographies($config);
@include mat.core();

@import "./partials/scrollbars";
@import "./partials/misc";
@import "./partials/typography";
@import "../shared/page-layout/page-layout.directive";
