@use "sass:list";

@use '@angular/material' as mat;
//@import "@angular/material/theming";
@import "./mixins";

$config: mat.define-typography-config(
  $font-family: var(--font-family),
  $headline-1: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $headline-2: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $headline-3: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  $headline-4: mat.define-typography-level(34px, 40px, 400),
  $headline-5: mat.define-typography-level(24px, 32px, 400),
  $headline-6: mat.define-typography-level(18px, 26px, 500),
  $subtitle-1: mat.define-typography-level(16px, 28px, 400),
  $subtitle-2: mat.define-typography-level(12px, 24px, 500),
  $body-1: mat.define-typography-level(14px, 24px, 400),
  $body-2: mat.define-typography-level(12px, 20px, 500),
  $caption: mat.define-typography-level(12px, 20px, 400),
  $button: mat.define-typography-level(14px, 14px, 500)
);
/* TODO(mdc-migration): No longer supported. Use `body-1` instead. ut: mat.define-typography-level(14px, 1.125, 400)

*/
:root {
  --font-family: proxima-nova, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  // Typography
  --font-weight-medium: 500;
  --font-caption: #{mat.font-weight($config, caption) list.slash(mat.font-size($config, caption), mat.line-height($config, caption)) mat.font-family($config, caption)};
  --font-body-1: #{mat.font-weight($config, body-1) list.slash(mat.font-size($config, body-1), mat.line-height($config, body-1)) mat.font-family($config, body-1)};
  --font-body-2: #{mat.font-weight($config, body-2) list.slash(mat.font-size($config, body-2), mat.line-height($config, body-2)) mat.font-family($config, body-2)};
  --font-subheading-1: #{mat.font-weight($config, body-1) #{mat.font-size($config, body-1)}/#{mat.line-height($config, body-1)} mat.font-family($config, body-1)};
  --font-subheading-2: #{mat.font-weight($config, subtitle-1) #{mat.font-size($config, subtitle-1)}/#{mat.line-height($config, subtitle-1)} mat.font-family($config, subtitle-1)};
  --font-headline: #{mat.font-weight($config, headline-5) #{mat.font-size($config, headline-5)}/#{mat.line-height($config, headline-5)} mat.font-family($config, headline-5)};
  --font-title: #{mat.font-weight($config, headline-6) #{mat.font-size($config, headline-6)}/#{mat.line-height($config, headline-6)} mat.font-family($config, headline-6)};
  --font-display-1: #{mat.font-weight($config, headline-4) #{mat.font-size($config, headline-4)}/#{mat.line-height($config, headline-4)} mat.font-family($config, headline-4)};
  --font-display-2: #{mat.font-weight($config, headline-3) #{mat.font-size($config, headline-3)}/#{mat.line-height($config, headline-3)} mat.font-family($config, headline-3)};
  --font-display-3: #{mat.font-weight($config, headline-2) #{mat.font-size($config, headline-2)}/#{mat.line-height($config, headline-2)} mat.font-family($config, headline-2)};
  --font-display-4: #{mat.font-weight($config, headline-1) #{mat.font-size($config, headline-1)}/#{mat.line-height($config, headline-1)} mat.font-family($config, headline-1)};
}
