fury-coming-soon {
  .card {
    background: $theme-card;
  }

  .card-footer {
    background: $theme-app-bar;
  }

  .logo svg {
    fill: $theme-text;
  }
}
