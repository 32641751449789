.fury-page-layout {
  background: $fcmat-background-light;
}

.fury-page-layout-header {
  background: $fcmat-background-light;
  color: $theme-color-primary-contrast;

  fury-breadcrumbs {
    .crumb, .link {
      color: $light-secondary-text;

      &:hover {
        color: $light-primary-text;
      }
    }

    .crumb.current, .crumb.current .link {
      color: $light-primary-text;
    }
  }
}

.fury-page-layout-content {
  background: $fcmat-background-light;
}

fury-page-layout-footer {
  button {
    margin: 0 4px 0 4px;
  }
}

fury-page-layout-footer.fcmat-white {
  button {
    background: $fcmat-background-light;
    color: $theme-color-primary-contrast;

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}
