.fury-tabs {
  .mat-mdc-tab-labels {
    background: $theme-app-bar;
    .mat-mdc-tab {
       &.mdc-tab--active {
        color: $theme-text;
        opacity: 1;
      }
    }
  }
    .mat-mdc-tab-body-wrapper {
    flex: 1;
  }
}
